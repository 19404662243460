import AbstractRest from "./AbstractRest.ts";

class ImageRest extends AbstractRest {

    constructor() {
        super("images");
    }

    getUrlById(id: string): string {
        return this.baseUrl + "/" + id;
    }

}

export default ImageRest;