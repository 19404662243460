declare module '@mui/material/styles' {
    interface Palette {
        workshop: Palette['primary'];
        tutorial: Palette['primary'];
        user: Palette['primary'];
        material: Palette['primary'];
        lab: Palette['primary'];
        asset: Palette['primary'];
        challenge: Palette['primary'];
    }

    interface PaletteOptions {
        workshop?: PaletteOptions['primary'];
        tutorial?: PaletteOptions['primary'];
        user?: PaletteOptions['primary'];
        material?: PaletteOptions['primary'];
        lab?: PaletteOptions['primary'];
        asset?: PaletteOptions['primary'];
        challenge?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        workshop: true;
        tutorial: true;
        user: true;
        material: true;
        lab: true;
        asset: true;
        challenge: true;
    }
}

const colors = {
    primary: "#e0e0e0",
    secondary: "#4285f4",
    workshop: "#fff176",
    tutorial: "#81c784",
    user: "#ba68c8",
    material: "#ffb74d",
    lab: "#212121",
    asset: "#64b5f6",
    challenge: "#4db6ac"
}

export default colors;