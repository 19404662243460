import {CardGridSingleLineNew, StandardCard} from "@laser-project/kyui";
import React, {useMemo} from "react";
import {IFeature} from "../../../types/models/Feature.ts";
import ImageRest from "../../../rest/ImageRest.ts";

export interface FeatureSliderProps{
    filteredFeatures: (IFeature[] | null)
}

export function FeatureSlider(props: Readonly<FeatureSliderProps>){

    const imageRest = useMemo(() => new ImageRest(), []);

    return (
        <CardGridSingleLineNew cards={props.filteredFeatures?.map(feature => {

                    if (!feature) {
                        return undefined;
                    }

                    return <StandardCard
                        image={imageRest.getUrlById(feature.imageId)}
                        imageAlt={"Image showing the feature " + feature.type}
                        title={feature.description ? feature.description : feature.type}
                        chips={feature.chips}
                        type={"feature"}
                        key={feature._id}
                    />
                }
            ) ??
            [
                <StandardCard isLoading key={1} chips={[]} image={""} imageAlt={""} title={""}
                              type={"model"}/>,
                <StandardCard isLoading key={2} chips={[]} image={""} imageAlt={""} title={""}
                              type={"model"}/>,
                <StandardCard isLoading key={3} chips={[]} image={""} imageAlt={""} title={""}
                              type={"model"}/>,
                <StandardCard isLoading key={4} chips={[]} image={""} imageAlt={""} title={""}
                              type={"model"}/>
            ]
        }/>
    )
}