class AbstractWebsocket {
    protected baseUrl: string;

    constructor(endpoint: string) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        this.baseUrl = window._env_.API_WS_URL + "/v1/" + endpoint;
    }

}

export default AbstractWebsocket