import {Box, Container, Stack} from "@mui/material";
import {ImageHeader} from "@laser-project/kyui";
import React, {useEffect, useMemo, useState} from "react";
import ImageRest from "../../rest/ImageRest.ts";
import {useNavigate} from "react-router-dom";
import {useCutJobApi} from "../CutJobPage";
import {IFeature} from "../../types/models/Feature.ts";
import {uniqBy} from "lodash";
import {IGauge, IGaugeSpec} from "../../types/models/Gauge.ts";
import {LoadingButton} from "@mui/lab";
import {StepByStepSwitcher} from "./StepByStepSwitcher";

export function StepByStep({jobId}: Readonly<{ jobId: string }>) {

    const imageRest = useMemo(() => new ImageRest(), []);
    const [images, setImages] = useState([])
    const [filteredFeatures, setFilteredFeatures] = useState<IFeature[] | null>([])
    const [renderPreviewStepTarget, setRenderPreviewStepTarget] = useState<number>(0)
    const navigate = useNavigate();
    const {
        handleUserInputSubmit,
        isSubmitting,
        activeActionId,
        job,
        features
    } = useCutJobApi({jobId})

    useEffect(() => {
        setImages([imageRest.getUrlById(job?.imageId ?? "")])
    }, [imageRest, job, job?.imageId]);

    useEffect(() => {
        let filteredFeatures = uniqBy(features, (feature: IFeature) => feature.groupingKey);
        const blacklistedDependencyKeys: string[] = []
        filteredFeatures = filteredFeatures.map(feature => {
            const newFeature = {...feature};
            const cleanedDeps: (IGauge | IGaugeSpec)[] = [];
            newFeature.dependencies?.forEach(dep => {
                if (!blacklistedDependencyKeys.includes(dep.groupingKey)) {
                    blacklistedDependencyKeys.push(dep.groupingKey);
                    cleanedDeps.push(dep);
                }
            })
            newFeature.dependencies = cleanedDeps;
            return newFeature;
        })

        filteredFeatures.forEach(
            feature => feature.entityType = "feature"
        )

        setFilteredFeatures(filteredFeatures)
    }, [features]);

    useEffect(() => {
        if (renderPreviewStepTarget === 7){
            navigate("/job/"+jobId)
        }
    }, [renderPreviewStepTarget]);

    function stepByStep() {
        return <StepByStepSwitcher
            job={job}
            filteredFeatures={filteredFeatures}
            handleUserInputSubmit={handleUserInputSubmit}
            isSubmitting={isSubmitting}
            actionId={activeActionId}
            flattenedActionList={job?.actionOrder ?? []}
            renderPreviewStepTarget={renderPreviewStepTarget}
        />
    }

    return (
        <Box sx={{width: '100%', height: "100%", pt: 2, overFlowY: ""}}>
            <Container sx={{height: "100%"}}>
                <Stack spacing={3}>
                    <ImageHeader
                        images={images}
                        isLoading={!job}
                        menuActions={[
                            {
                                title: "Done",
                                onClick: () => navigate("/job/" + jobId)
                            }
                        ]}
                        actions={[
                            <LoadingButton
                                key={"animationNext"}
                                variant={"contained"}
                                color={"secondary"}
                                loading={false}
                                loadingPosition={"start"}
                                onClick={() => setRenderPreviewStepTarget((renderPreviewStepTarget + 1))}
                                sx={{
                                    // https://stackoverflow.com/questions/40326565/how-do-you-change-the-stepper-color-on-react-material-ui
                                    '&.Mui-disabled': {
                                        backgroundColor: 'grey.300',
                                        //backgroundColor: 'success.dark',
                                        //color: "success.contrastText"
                                    },
                                }}
                            >
                                Next
                            </LoadingButton>
                        ]}
                    />
                    {stepByStep()}
                </Stack>
            </Container>
        </Box>
    )

}