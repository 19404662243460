import {AuthProvider, User} from "oidc-react";
import {ReactNode} from "react";

export interface AuthWrapperProps {
    children: ReactNode
}

export function AuthWrapper(props: AuthWrapperProps) {

    const oidcConfig = {
        onSignIn: ( userData: User) => {
            console.log(userData)
            window.location.search = ""
        },
        // @ts-expect-error _env_ is injected
        authority: window._env_.AUTH_AUTHORITY,
        // @ts-expect-error _env_ is injected
        clientId: window._env_.AUTH_CLIENT,
        redirectUri: window.location.origin + window.location.pathname,
        automaticSilentRenew: true,
        autoSignIn: true
    };


    return (
        <AuthProvider {...oidcConfig} onBeforeSignIn={() => "Loading"}>
            {props.children}
        </AuthProvider>
    )

}