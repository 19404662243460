import {ITimestamped} from "./Database.js";
import {IFeature} from "./Feature.ts";
import {IAction} from "./Action.ts";
import {IUserInputRequest} from "./UserInputRequest.ts";


export enum JOB_STATUS {
    CREATED = "CREATED",
    DEPENDENCIES_ANALYZED = "DEPENDENCIES_ANALYZED",
    RUNNING = "RUNNING",
    WAITING_FOR_INPUT = "WAITING",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
    DELETED = "DELETED",
}

export interface IJobSpec {
    cutplan: string;
    title?: string;
    // user?: IUser;
    imageId?: string;
    cutterIds?: string[];
    sheets?: ISheetData[];
    sheetPrototype: ISheetDataPrototype;
    features?: IFeature[];
}


export interface IJob extends IJobSpec, ITimestamped {
    _id: string;
    status: JOB_STATUS;
    actionOrder?: IAction[];
    lastActiveActionId?: string | null;
    currentInputRequest?: IUserInputRequest | null;
}


export interface IJobModel extends IJob {
    _id: string;
}

// ToDo: Move somewhere else
export interface IReadonlyVector2 {
    readonly x: number;
    readonly y: number;
}

// ToDo: Move somewhere else
export interface IReadonlyPolygon2D {
    readonly points: IReadonlyVector2[];
}

export interface ISheetData {
    material: string;
    thickness: number;
    outline: IReadonlyPolygon2D;
}

export interface ISheetDataPrototype {
    outline: IReadonlyPolygon2D;
}