import {IWebsocketAPIMessage, KNOWN_WEBSOCKET_API_MESSAGES,} from "./WebsocketAPITypes.js";

export default class WebsocketAPISerde {
  public static decode(message: string): IWebsocketAPIMessage {
    let out_message;

    if (message === "pong"){
      return {
        type: KNOWN_WEBSOCKET_API_MESSAGES.PONG
      }
    }

    try {
      out_message = JSON.parse(message) as IWebsocketAPIMessage;
    } catch (e) {
      throw new Error("could not parse json");
    }

    if (!out_message.type) {
      throw new Error("illegal message with no type received");
    }
    if (
      !Object.values(KNOWN_WEBSOCKET_API_MESSAGES).includes(out_message.type)
    ) {
      throw new Error("illegal message unknown type received");
    }
    return out_message;
  }

  public static encode<T extends IWebsocketAPIMessage>(message: T): string {
    return JSON.stringify(message);
  }
}
