import {createTheme, responsiveFontSizes} from "@mui/material";
import colors from "./colors";
import baseTheme from "./baseTheme";

let lightTheme = createTheme(baseTheme, {
    palette: {
        mode: "light",
        primary: baseTheme.palette.augmentColor({
            color: {
                main: colors.primary,
            },
            name: "primary"
        }),
        secondary: baseTheme.palette.augmentColor({
            color: {
                main: colors.secondary
            },
            name: "secondary"
        }),
    },
})

lightTheme = createTheme(lightTheme, {
    palette: {
        workshop: lightTheme.palette.augmentColor({
            color: {
                main: colors.workshop,
            },
            name: 'workshop',
        }),
        tutorial: lightTheme.palette.augmentColor({
            color: {
                main: colors.tutorial,
            },
            name: 'tutorial',
        }),
        user: lightTheme.palette.augmentColor({
            color: {
                main: colors.user,
            },
            name: 'user',
        }),
        material: lightTheme.palette.augmentColor({
            color: {
                main: colors.material,
            },
            name: 'material',
        }),
        lab: lightTheme.palette.augmentColor({
            color: {
                main: colors.lab,
            },
            name: 'lab',
        }),
        asset: lightTheme.palette.augmentColor({
            color: {
                main: colors.asset,
            },
            name: 'workshop',
        })
    }

})

const theme = responsiveFontSizes(lightTheme);

export default theme;
