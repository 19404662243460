import DesktopNavigation from "./DesktopNavigation/DesktopNavigation.tsx";
import {ReactNode, useEffect, useState} from "react";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import {useNavigatorOnLine} from "../../hooks/useNavigatorOnline.tsx";

export interface NavigationProps {
    children: ReactNode
}

function Navigation(props: NavigationProps) {
    const [offlineSnackbar, setOfflineSnackbar] = useState(null)
    const isOnline = useNavigatorOnLine();

    useEffect(() => {

        if (!isOnline) {
            setOfflineSnackbar(enqueueSnackbar('You seem to be offline. Please reconnect to Network!', {
                variant: 'error',
                persist: true
            }))
        } else {
            if (!offlineSnackbar) {
                return
            }
            closeSnackbar(offlineSnackbar.id)
        }

    }, [isOnline]);

    return (
            <DesktopNavigation>
            {
                props.children
            }
            </DesktopNavigation>
    )
}

export default Navigation;