import {useEffect, useState} from "react";
import {Box, CircularProgress, Fade, Typography} from "@mui/material";
import LoadingSpinnerStyles from "./LoadingSpinnerStyles";
import PropTypes from "prop-types";

export interface LoadingSpinnerProps {
    message: string
}

function LoadingSpinner(props: LoadingSpinnerProps) {

    const [loadingTextShowing, setLoadingTextShowing] = useState(false);

    const {message} = props;

    useEffect(() => {
        const timeout = setTimeout(() => setLoadingTextShowing(true), 2000);
        return () => {
            clearTimeout(timeout);
        }
    }, [])


    return (
        <Box sx={LoadingSpinnerStyles.wrapper}>
            <Box sx={LoadingSpinnerStyles.child}>
                <CircularProgress color={"secondary"}/>
                <Fade in={loadingTextShowing}>
                    <Typography variant={"body1"} sx={LoadingSpinnerStyles.message}>{message}</Typography>
                </Fade>
            </Box>
        </Box>
    )
}

LoadingSpinner.propTypes = {
    message: PropTypes.string
}

LoadingSpinner.defaultProps = {
    message: "Loading"
}

export default LoadingSpinner;
