import React, {useEffect} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css";
import {SWHandler} from "./components/SWHandler/SWHandler.tsx";
import {SnackbarProvider} from "notistack"; // Specify weight and style
import * as Sentry from "@sentry/react";
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";

Sentry.init({
    // @ts-expect-error _env_ will be injected into window in index.html
    dsn: window._env_?.SENTRY_DSN,
    enabled: import.meta.env.PROD,
    release: import.meta.env.SENTRY_RELEASE,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/kyub\.com\/api/]
        }),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        })
        /*new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),*/
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <SnackbarProvider>
            <SWHandler>
                <SnackbarProvider>
                    <App/>
                </SnackbarProvider>
            </SWHandler>
        </SnackbarProvider>
    </React.StrictMode>,
)
