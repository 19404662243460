import {useAuth} from "oidc-react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner.tsx";
import {ReactNode, useEffect, useState} from "react";
import axios from "axios";

export function SignInCheck(props: { children: ReactNode }) {

    const auth = useAuth()
    const [isAuthSet, setAuthSet] = useState<boolean>(false)

    useEffect(() => {

        if (auth.isLoading || auth.userData === null) {
            console.log("Skipping auth refresh")
            return
        }
        console.log("Refreshing Authorization Bearer")

        axios.defaults.headers.common["Authorization"] = "Bearer " + auth.userData.access_token
        setAuthSet(true)
    }, [auth?.userData?.access_token]);

    if (auth.isLoading || auth.userData === null || !isAuthSet) {
        return (
            <LoadingSpinner message={"Loading User Account"}/>
        )
    }


    return props.children

}