import {Grid} from "@mui/material";
import {animated, AnimationResult, SpringValue, useSprings} from "@react-spring/web";
import React, {useEffect, useMemo} from "react";
import theme from "../../../Theme/themes/lightTheme.ts";
import {AnimationElement} from "../TransformAnimation.types.ts";
import {IFeature} from "../../../../types/models/Feature.ts";
import {IGauge, IGaugeSpec} from "../../../../types/models/Gauge.ts";
import ImageRest from "../../../../rest/ImageRest.ts";
import {StandardCard} from "@laser-project/kyui";
import {IGaugeDedupe} from "../../../../pages/CutJobPage";

export interface TurnAnimationProps {
    animationState: number,
    height: number,
    width: number,
    onAnimationFinished: () => void,
    cards: AnimationElement[]
}

export function TurnAnimation(props: TurnAnimationProps) {

    const imageRest = useMemo(() => new ImageRest(), [])

    const [springs, api2] = useSprings(
        props.cards.length,
        () => {
            return {
                from: {
                    x: 0,
                    y: 0,
                },
            }
        },
        []
    )

    const switchBack = (e: AnimationResult<SpringValue<{ x: number, y: number }>>) => {
        if (e.value.x !== 0 && e.value.y !== 0) {
            props.onAnimationFinished()
        }
    }

    useEffect(() => {
        if (props.animationState != 1) {
            return;
        }
        if (!props.height || !props.width) {
            console.log("Height or width is null", props.height, props.width)
            return;
        }
        api2.start((i) => {
            const spacing = Number(theme.spacing(3).slice(0, -2))
            return {
                from: {
                    x: 0,
                    y: 0
                },
                to: [
                    {
                        y: i * (props.height + spacing),
                        x: 0,
                    },
                    {
                        y: i * (props.height + spacing),
                        x: -(i * (props.width + spacing)),
                    }
                ],
                onRest: switchBack
            }
        })
    }, [props.animationState]);

    function renderCard(value: (IFeature | IGauge | IGaugeSpec | IGaugeDedupe)) {
        if (!value) {
            return
        }
        return <StandardCard
            image={imageRest.getUrlById(value.imageId)}
            imageAlt={""}
            title={value.description}
            chips={value.chips}
            type={"feature"}
        />;
    }

    return (
        <Grid container spacing={3} direction={"row"}>
            {
                springs.map((divAnimationStyles, i) => {
                    return (
                        <Grid item xs={3} key={i}>
                            <animated.div style={{...divAnimationStyles}}>
                                <animated.div>
                                    {/* @ts-expect-error at is a valid function of a list */}
                                    {renderCard(props.cards.at(i)?.primary)}
                                </animated.div>
                            </animated.div>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}