import {LoadingButton, LoadingButtonProps} from "@mui/lab";
import {useState} from "react";

export function LoadingSubmitButton(props: LoadingButtonProps) {
  const [isLoading, setIsLoading] = useState(false);

  function handleClick(e) {
    setIsLoading(true);
    props.onClick(e);
  }

  return <LoadingButton {...props} loading={props.loading || isLoading} onClick={handleClick}>{props.children}</LoadingButton>;
}
