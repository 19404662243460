import {useTheme} from "@mui/material";
import React, {useMemo} from "react";
import ImageRest from "../../../../rest/ImageRest.ts";
import {animated, useSpring} from "@react-spring/web";
import {UniteAnimationProps} from "./UniteAnimation.types.ts";
import {StandardCard} from "@laser-project/kyui";

export function UniteAnimation(props: UniteAnimationProps) {
    const theme = useTheme();
    const spacing = Number(theme.spacing(3).slice(0, -2))
    const imageRest = useMemo(() => new ImageRest(), []);
    // calculate the old position

    let sumPreviousCards = 0;
    let sumPreviousCardsFiltered = 0;
    props.state.forEach((element, currentRow) => {
        if (currentRow > props.row) {
            return;
        }
        else if (currentRow === props.row) {
            sumPreviousCards += element.secondary.filter(
                (value, index) => index < props.column
            ).length
            sumPreviousCardsFiltered += element.secondary.filter(
                (value, index) => index < props.column
            ).filter(
                (value) => value.keep
            ).length
        } else {
            sumPreviousCards += element.secondary.length
            sumPreviousCardsFiltered += element.secondary.filter(value => value.keep).length
        }
    })

    const oldColumn = sumPreviousCards % 4;
    const oldRow = Math.floor(sumPreviousCards / 4);
    const newColumn = sumPreviousCardsFiltered % 4;
    const newRow = Math.floor(sumPreviousCardsFiltered / 4);


    const {x, y} = useSpring({
        from: {
            x: (oldColumn - newColumn) * (props.width + spacing),
            y: (oldRow - newRow) * (props.width + spacing),
        },
        to: {
            x: 0,// props.row > 0 ? column_offset * (props.width + spacing) : 0,
            y: 0,//props.row > 0 ? -((props.row) * (props.height + spacing)) : 0,
        },
        //delay: 500,
        onRest: (props.onComplete ? () => {
            props.onComplete();
        } : undefined)
    })

    return (
        <animated.div style={{
            x, y
        }}>
            <StandardCard
                image={imageRest.getUrlById(props.children.imageId)}
                imageAlt={""}
                title={props.children.description}
                chips={props.children.chips}
                type={props.children.entityType}
            />
        </animated.div>
    )
}
