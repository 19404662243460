import {createTheme} from "@mui/material";
import ComponentsTheme from "./componentsTheme";


const baseTheme = createTheme({
    breakpoints: {
        keys: ["xs", "sm", "md", "lg", "xl"],
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536
        }
    },
    components: ComponentsTheme
})

export default baseTheme;
