import React, {useEffect, useMemo, useRef, useState} from "react";
import {Box, Grid} from "@mui/material";
import {IFeature} from "../../../types/models/Feature.ts";
import {IGauge, IGaugeSpec} from "../../../types/models/Gauge.ts";
import ImageRest from "../../../rest/ImageRest.ts";
import {SideExpander} from "./SideExpander/SideExpander.tsx";
import {UniteAnimation} from "./UniteAnimation/UniteAnimation.tsx";
import {DedupeAnimation} from "./DedupeAnimation/DedupeAnimation.tsx";
import {AnimationContainerProps, AnimationElement} from "./TransformAnimation.types.ts";
import {TurnAnimation} from "./TurnAnimation/TurnAnimation.tsx";
import {HideAnimation} from "./HideAnimation/HideAnimation.tsx";
import {StandardCard} from "@laser-project/kyui";
import {IGaugeDedupe} from "../../../pages/CutJobPage";


export function TransformAnimation(props: AnimationContainerProps) {
    const [animationState, setAnimationState] = useState(0)
    const container: React.RefObject<HTMLInputElement> = useRef(null);
    const [height, setHeight] = useState<null | number>(null);
    const [width, setWidth] = useState<null | number>(null);
    const [internalCards, setInternalCards] = useState<null | AnimationElement[]>(null);
    const [animationCompleted, setAnimationCompleted] = useState<boolean>(false);
    const imageRest = useMemo(() => new ImageRest(), []);

    useEffect(() => {
        if (!container.current) {
            return
        }
        setHeight(Number(container.current.offsetHeight))
        setWidth(container.current.offsetWidth)
    }, [container, internalCards]);

    useEffect(() => {

        const workingInternalCards = [...props.cards]
        setInternalCards(workingInternalCards)
    }, [props.cards]);

    useEffect(() => {

        if (props.animationTarget) {
            if (props.animationTarget > animationState) {
                // console.log("AT is larger increasing to ", animationState + 1)
                setAnimationState(animationState + 1)
            }
            if (props.animationTarget < animationState) {
                // console.log("AT is smaller increasing to ", animationState + 1)
                setAnimationState(0)
            }
            setAnimationCompleted(false)

        }

    }, [props.animationTarget]);

    function renderCard(value: (IFeature | IGauge | IGaugeSpec | IGaugeDedupe), renderRef?: boolean) {
        if (!value) {
            return
        }
        const card = <StandardCard
            image={imageRest.getUrlById(value.imageId)}
            imageAlt={""}
            title={value.description}
            chips={value.chips}
            type={value.entityType}
        />

        if (renderRef) {
            return (
                <Box ref={container}>{card}</Box>
            )
        }
        return card;
    }

    useEffect(() => {

        if (animationState === props.animationTarget && props.onComplete && animationCompleted) {
            props.onComplete()
        }

    }, [animationState, props]);

    if (!internalCards) {
        return ""
    }

    function renderSteps() {
        switch (animationState) {
            case 0:
                return (
                    <Grid container spacing={3} direction={"row"}>
                        {internalCards?.map((card, index) => <Grid item xs={3}
                                                                   key={index}>{renderCard(card.primary, index === 0)}</Grid>)}
                    </Grid>
                )
            case 1:
                return <TurnAnimation animationState={animationState} cards={internalCards} height={height}
                                      width={width} onAnimationFinished={() => {
                    setAnimationCompleted(true)
                }}/>

            case 2:
                return (
                    <Grid container spacing={3}>
                        {internalCards?.map((card, i) => (
                            <>
                                {[...card.secondary, card.primary].map((subcard, j) => (
                                    <Grid item xs={3}
                                          key={i + "-" + j}
                                    >
                                        <SideExpander width={width} order={j} onComplete={() => {
                                            setAnimationCompleted(true)
                                            //setAnimationState(3)
                                        }}>
                                            {renderCard(subcard)}
                                        </SideExpander>
                                    </Grid>
                                ))}
                                <Grid item xs={12 - ((card.secondary.length + 1) % 4 * 3)}/>
                            </>
                        ))}
                    </Grid>
                )
            case 3: {
                // deduping
                return (
                    <Grid container spacing={3}>
                        {internalCards?.map((card, i) => {
                            return (
                                <>
                                    {[...card.secondary, {...card.primary, keep: true}].map((card, j) => (
                                        <Grid item xs={3}
                                              key={i + "-" + j}
                                        >
                                            <DedupeAnimation
                                                children={card}
                                                onComplete={() => setAnimationCompleted(true)}
                                            />
                                        </Grid>
                                    ))}
                                    <Grid item xs={12 - ((card.secondary.length + 1) % 4 * 3)}/>
                                </>
                            );
                        })}
                    </Grid>
                )
            }
            case 4: {
                // remove features
                return (
                    <Grid container spacing={3}>
                        {internalCards?.map((card, i) => {
                            return (
                                <>
                                    {[...card.secondary, {...card.primary, keep: false}].map((card, j) => (
                                        <Grid item xs={3}
                                              key={i + "-" + j}
                                        >
                                            <DedupeAnimation
                                                children={card}
                                                onComplete={() => setAnimationCompleted(true)}
                                            />
                                        </Grid>
                                    ))}
                                    <Grid item xs={12 - ((card.secondary.length + 1) % 4 * 3)}/>
                                </>
                            );
                        })}
                    </Grid>
                )
            }
            case 5: {
                // remove features
                return (
                    <Grid container spacing={3}>
                        {internalCards?.map((card, i) => {
                            return (
                                <>
                                    {[...card.secondary, {...card.primary, keep: false}].map((card, j) => (
                                        <Grid item xs={3}
                                              key={i + "-" + j}
                                        >
                                            <HideAnimation
                                                children={card}
                                                onComplete={() => setAnimationCompleted(true)}
                                            />
                                        </Grid>
                                    ))}
                                    <Grid item xs={12 - ((card.secondary.length + 1) % 4 * 3)}/>
                                </>
                            );
                        })}
                    </Grid>
                )
            }
            case 6: {
                // Uniting
                return (
                    <Grid container spacing={3}>
                        {internalCards?.map((card, i) => (
                            <>
                                {[...card.secondary].map((card, j) => (
                                    <>
                                        {
                                            card.keep ? (
                                                    <Grid item xs={3}
                                                          key={i + "-" + j}
                                                    >
                                                        <UniteAnimation
                                                            row={i}
                                                            column={j}
                                                            height={height ?? 0}
                                                            width={width ?? 0}
                                                            state={internalCards}
                                                            children={card}
                                                            onComplete={() => setAnimationCompleted(true)}
                                                        />
                                                    </Grid>
                                                )
                                                : undefined
                                        }
                                    </>
                                ))}
                            </>
                        ))}
                    </Grid>
                )
            }

        }
    }


    return (
        <>
            {renderSteps()}
        </>
    )
}

TransformAnimation.defaultProps = {
    animationTarget: 0
}