import {useRegisterSW} from "virtual:pwa-register/react";
import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {closeSnackbar, enqueueSnackbar, SnackbarKey} from "notistack";
import Button from "@mui/material/Button";
import {Close} from "@mui/icons-material";
import {CircularProgress} from "@mui/material";
import {LoadingSubmitButton} from "../LoadingSubmitButton/LoadingSubmitButton.tsx";

export function SWHandler(props: { children: ReactElement }) {

  const [isUpdatingServiceWorker, setUpdatingServiceWorker] = useState<boolean>(false);
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegisteredSW(r) {
      // eslint-disable-next-line prefer-template
      console.log("SW Registered: " + r);
    },
    onRegisterError(error) {
      console.log("SW registration error", error);
    }
  });

  const close = (snackBarId:SnackbarKey) => {
    setOfflineReady(false);
    setNeedRefresh(false);
    closeSnackbar(snackBarId)
  };

  const toastActions = useCallback((snackBarId: SnackbarKey) => {
    return(
      <>
        <LoadingSubmitButton
          onClick={() => {
            updateServiceWorker(true);
            }
          }
          loadingIndicator={
            <CircularProgress color="secondary" size={16} />
          }
        >
          Update
        </LoadingSubmitButton>
        <Button onClick={() => close(snackBarId)}>
          <Close />
        </Button>
      </>
    )
  }, [isUpdatingServiceWorker])

  useEffect(() => {
    if (!needRefresh) {
      return;
    }
    enqueueSnackbar("New Version available", {
      action: (snackBarId) => toastActions(snackBarId),
      persist: true
    });
  }, [needRefresh]);


  return props.children;
}
