import axios from "axios";

class AbstractRest {
    protected baseUrl: string;

    constructor(endpoint: string) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        this.baseUrl = window._env_.API_URL + "/v1/" + endpoint;

    }

    get = () => {
        return axios.get(this.baseUrl);
    }


}

export default AbstractRest