import React, {useEffect, useMemo, useState} from "react";
import JobRest from "../../../rest/JobRest.ts";
import JobWebsocket from "../../../websocket/JobWebsocket.ts";
import {IJob, JOB_STATUS} from "../../../types/models/Job.ts";
import WebsocketAPISerde from "../../../types/WebsocketAPISerde.ts";
import {
    IActionUpdatedMessage,
    IActiveActionSetMessage,
    IDependenciesAnalyzedMessage,
    IJobStatusChangeMessage,
    IStartJobMessage,
    IUpdateActionOrderMesssage,
    IUserInputReceivedMessage,
    IUserInputRequestMessage,
    KNOWN_WEBSOCKET_API_MESSAGES
} from "../../../types/WebsocketAPITypes.ts";
import useWebSocket from "react-use-websocket";
import {useWebsocketNotifier} from "../../../hooks/useWebsocketNotifier/useWebsocketNotifier.tsx";
import {uniqBy} from "lodash";
import {IFeature} from "../../../types/models/Feature.ts";
import {IGaugeDedupe} from "../Dedupe.types.ts";

export interface useCutJobApi {
    jobId: string
}

export function useCutJobApi(props: useCutJobApi) {
    const jobRest = useMemo(() => new JobRest(), []);
    const jobWebsocket = useMemo(() => new JobWebsocket(), []);
    const [job, setJob] = React.useState<IJob | null>(null);
    const [activeActionId, setActiveActionId] = React.useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isStartingJob, setIsStartingJob] = useState<boolean>(false)
    const [lastErrorMessage, setLastErrorMessage] = useState<(string | null)>(null)
    const [features, setFeatures] = useState<(IFeature[] | null)>(null)


    const {sendMessage, lastMessage, readyState} = useWebSocket(
        jobWebsocket.getUpdatesByJobId(props.jobId!),
        {
            shouldReconnect: () => true,
            heartbeat: true
        });

    useWebsocketNotifier({readyState: readyState})


    function onJobStatusChange(parsedMessage: IJobStatusChangeMessage) {
        const newJob = {...job}
        newJob.features.forEach(
            feature => {
                feature.entityType = "feature"
                feature.dependencies.forEach(g => g.entityType = "gauge")
            }
        )
        newJob.status = parsedMessage.data.status
        if (newJob.status === JOB_STATUS.RUNNING) {
            setIsStartingJob(false)
        }
        setJob(newJob);
    }

    function onDependenciesAnalyzed(parsedMessage: IDependenciesAnalyzedMessage) {
        const newJob = {...job};
        console.info("Updating features to new version")
        newJob.features = parsedMessage.data.features;

        const groupingKeysActions = [];
        // ToDo: remove duplication
        newJob.features = uniqBy(newJob.features, feat => feat.groupingKey).map(filteredFeature => {
            filteredFeature.dependencies = filteredFeature?.dependencies?.map(dep => {
                const newDep = {...dep, keep: false} as IGaugeDedupe
                if (!groupingKeysActions.includes(dep.groupingKey)) {
                    newDep.keep = true
                    groupingKeysActions.push(dep.groupingKey)
                }
                return newDep
            });
            return filteredFeature;
        });
        setJob(newJob);
    }

    function onUpdateActionOrder(parsedMessage: IUpdateActionOrderMesssage) {
        setJob(
            {
                ...job,
                actionOrder: parsedMessage.data.actionOrder
            } satisfies IJob
        );
    }

    function onActiveActionSet(parsedMessage: IActiveActionSetMessage) {
        setActiveActionId(parsedMessage.data.actionId)
        setIsSubmitting(false)
        setLastErrorMessage(null)
    }

    function onUserInputRequest(parsedMessage: IUserInputRequestMessage) {
        setIsSubmitting(false)
        const index = job.actionOrder.findIndex(value => {
            return value._id === parsedMessage.data.actionId
        })
        const newFlattenedActionList = [...job.actionOrder];
        if (parsedMessage.data.lastErrorMessage) {
            setLastErrorMessage(parsedMessage.data.lastErrorMessage)
        }
        if (index >= 0) {
            newFlattenedActionList[index].isLoading = false

            setJob({...job, actionOrder: newFlattenedActionList})
        } else {
            console.warn("Could not stop loading. Could not find corresponding id.", parsedMessage.data.actionId)
        }
    }

    function onActionUpdated(parsedMessage: IActionUpdatedMessage) {
        const index = job.actionOrder?.findIndex(value => value._id === parsedMessage.data._id)
        const newFlattenedActionList = [...job.actionOrder];
        console.log("Action updated!", index)
        if (index >= 0) {
            console.log("Updated Action", newFlattenedActionList[index], parsedMessage.data)
            newFlattenedActionList[index] = parsedMessage.data
            setJob({...job, actionOrder: newFlattenedActionList})
        } else {
            console.warn("Could not stop loading. Could not find corresponding id.")
        }
    }

    useEffect(() => {
        if (!lastMessage) {
            console.log("rejected last message because it is empty", lastMessage)
            return
        }

        try {
            const message = WebsocketAPISerde.decode(lastMessage.data);
            //const message = JSON.parse(lastMessage.data) as IWebsocketAPIMessage;
            if (!job) {
                console.warn("Job is not set")
                return;
            }
            console.log("parsed json", message)
            switch (message.type) {
                case KNOWN_WEBSOCKET_API_MESSAGES.JOB_STATUS_CHANGE: {
                    const parsedMessage = message as IJobStatusChangeMessage;
                    onJobStatusChange(parsedMessage)
                    break;
                }
                case KNOWN_WEBSOCKET_API_MESSAGES.DEPENDENCIES_ANALYZED: {
                    const parsedMessage = message as IDependenciesAnalyzedMessage;
                    onDependenciesAnalyzed(parsedMessage)
                    break;
                }
                case KNOWN_WEBSOCKET_API_MESSAGES.JOB_STARTED:
                    console.warn("Not implemented - job started")
                    break;
                case KNOWN_WEBSOCKET_API_MESSAGES.CREATE_JOB:
                    console.warn("Not implemented - Create Job")
                    break;
                case KNOWN_WEBSOCKET_API_MESSAGES.USER_INPUT_RECEIVED:
                    console.warn("Not implemented - UserInputReceived")
                    break;
                case KNOWN_WEBSOCKET_API_MESSAGES.UPDATE_ACTION_ORDER: {
                    const parsedMessage = message as IUpdateActionOrderMesssage;
                    onUpdateActionOrder(parsedMessage)
                    break;
                }
                case KNOWN_WEBSOCKET_API_MESSAGES.ACTIVE_ACTION_SET: {
                    // {"type":"ACTIVE_ACTION_SET","data":{"actionId":"3a6ada62-4268-4383-a251-520b3f6f4618"}}
                    const parsedMessage = message as IActiveActionSetMessage;
                    onActiveActionSet(parsedMessage)
                    break;
                }
                case KNOWN_WEBSOCKET_API_MESSAGES.USER_INPUT_REQUEST: {
                    const parsedMessage = message as IUserInputRequestMessage;
                    onUserInputRequest(parsedMessage)
                    break;
                }
                case KNOWN_WEBSOCKET_API_MESSAGES.ACTIVE_ACTION_UNSET: {
                    console.warn("Not implemented - active action unset")
                    break;
                }
                case KNOWN_WEBSOCKET_API_MESSAGES.ACTION_UPDATED: {
                    const parsedMessage = message as IActionUpdatedMessage;
                    onActionUpdated(parsedMessage)
                    break;
                }
                case KNOWN_WEBSOCKET_API_MESSAGES.GAUGE_UPDATED:
                    console.warn("Not implemented - gauge updated")
                    break;
                case KNOWN_WEBSOCKET_API_MESSAGES.LOG:
                    console.warn("Not implemented - log updates")
                    break;
                case KNOWN_WEBSOCKET_API_MESSAGES.PONG:
                    break;
                default:
                    console.warn("Did not interpret type", message.type, message)
            }
        } catch
            (e) {
            console.error("Failed to parse Message sent by cutterManager", e, lastMessage)
        }

    }, [lastMessage]);

    useEffect(() => {
        if (!props.jobId) {
            console.warn("Did not have jobId. Aborting")
            return;
        }
        jobRest
            .getById(props.jobId)
            .then((response) => {
                if (!response.data.data) {
                    throw new Error("No valid JobID given")
                }
                const newJob = response.data.data as IJob
                if (newJob?.lastActiveActionId) {
                    setActiveActionId(newJob?.lastActiveActionId)
                }
                const groupingKeysActions: string[] = [];

                newJob.features = uniqBy(newJob.features, feat => feat.groupingKey).map(filteredFeature => {
                    filteredFeature.dependencies = filteredFeature?.dependencies?.map(dep => {
                        const newDep = {...dep, keep: false} as IGaugeDedupe
                        if (!groupingKeysActions.includes(dep.groupingKey)) {
                            newDep.keep = true
                            groupingKeysActions.push(dep.groupingKey)
                        }
                        newDep.entityType = "gauge";
                        return newDep
                    });
                    filteredFeature.entityType = "feature";
                    return filteredFeature;
                });

                setJob(newJob);
            })
    }, [props.jobId, jobRest]);

    useEffect(() => {
        jobRest
            .getFeaturesByJobId(props.jobId)
            .then(response => {
                setFeatures(response.data.data)
            })
    }, [props.jobId, jobRest]);

    async function handleUserInputSubmit(answeredInput: (object | null)) {

        if (!job || !activeActionId) {
            console.error("No current user action specified")
            return
        }
        setIsSubmitting(true)

        const message = WebsocketAPISerde.encode({
            type: KNOWN_WEBSOCKET_API_MESSAGES.USER_INPUT_RECEIVED,
            data: {
                jobId: job._id,
                actionId: activeActionId,
                response: answeredInput ?? {}
            }
        } satisfies IUserInputReceivedMessage)

        sendMessage(message)
    }

    function handleStart() {

        if (!props.jobId) {
            console.warn("Cannot start job. JobId is not given")
            return
        }

        setIsStartingJob(true)

        const payload = WebsocketAPISerde.encode(
            {
                type: KNOWN_WEBSOCKET_API_MESSAGES.START_JOB,
                data: {
                    jobId: props.jobId,
                }
            } satisfies IStartJobMessage
        )

        console.log("Sending", payload)
        sendMessage(payload)
    }

    return {
        handleUserInputSubmit,
        handleStart,
        isSubmitting,
        activeActionId,
        job,
        lastErrorMessage,
        isStartingJob,
        features
    }

}