import {
  ICreateJobEvent,
  IDependenciesAnalyzedEvent,
  IJobStatusChangeEvent,
  ILogEvent,
  IStartJobEvent,
} from "./RXJSEvents/RXJSEventTypes.js";
import {IAction} from "./models/Action.ts";
import {IGauge} from "./models/Gauge.ts";
import {IUserInputRequestEvent} from "./IUserInputRequestEvent.ts";

export enum KNOWN_WEBSOCKET_API_MESSAGES {
  CREATE_JOB = "CREATE_JOB",
  START_JOB = "START_JOB",
  JOB_STARTED = "JOB_STARTED", // ToDo: This feels redundant with JOB_STATUS_CHANGE
  JOB_STATUS_CHANGE = "JOB_STATUS_CHANGE",
  USER_INPUT_RECEIVED = "USER_INPUT_RECEIVED",
  LOG = "LOG",
  DEPENDENCIES_ANALYZED = "DEPENDENCIES_ANALYZED",
  ACTION_UPDATED = "ACTION_UPDATED",
  GAUGE_UPDATED = "GAUGE_UPDATED",
  ACTIVE_ACTION_SET = "ACTIVE_ACTION_SET",
  ACTIVE_ACTION_UNSET = "ACTIVE_ACTION_UNSET",
  UPDATE_ACTION_ORDER = "UPDATE_ACTION_ORDER",
  USER_INPUT_REQUEST = "USER_INPUT_REQUEST",
  PONG ="PONG",
}

export interface IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES;
}

export interface ICreateJobMessage extends IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES.CREATE_JOB;
  data: ICreateJobEvent;
}

export interface IStartJobMessage extends IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES.START_JOB;
  data: {
    jobId: string;
  };
}

export interface IJobStatusChangeMessage extends IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES.JOB_STATUS_CHANGE;
  data: IJobStatusChangeEvent;
}

export interface IUserInputReceivedMessage extends IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES.USER_INPUT_RECEIVED;
  data: {
    jobId: string;
    actionId: string;
    response: object;
  };
}

export interface ILogMessage extends IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES.LOG;
  data: ILogEvent;
}

export interface IJobStartedMessage extends IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES.JOB_STARTED;
  data: IStartJobEvent;
}

export interface IDependenciesAnalyzedMessage extends IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES.DEPENDENCIES_ANALYZED;
  data: IDependenciesAnalyzedEvent;
}

export interface IActionUpdatedMessage extends IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES;
  data: IAction;
}

export interface IGaugeUpdatedMessage extends IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES;
  data: IGauge;
}

export interface IActiveActionSetMessage extends IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES.ACTIVE_ACTION_SET;
  data: {
    actionId: string;
  };
}

export interface IActiveActionUnsetMessage extends IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES.ACTIVE_ACTION_UNSET;
}


export interface IUpdateActionOrderMesssage extends IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES.UPDATE_ACTION_ORDER;
  data: {
    jobId: string;
    actionOrder: IAction[];
  };
}

export interface IUserInputRequestMessage extends IWebsocketAPIMessage {
  type: KNOWN_WEBSOCKET_API_MESSAGES.USER_INPUT_REQUEST;
  data: IUserInputRequestEvent;
}