import AbstractWebsocket from "./AbstractWebsocket.ts";

class JobWebsocket extends AbstractWebsocket {

    constructor() {
        super("jobs")
    }

    public getUpdatesByJobId(jobId: string) {
        return this.baseUrl + "?id=" + jobId;
    }

    public getAllUpdates() {
        return this.baseUrl;
    }

}

export default JobWebsocket