import {Route, Routes} from "react-router-dom";
import OverviewPage from "../../pages/OverviewPage/OverviewPage.tsx";
import {StepByStepPage} from "../../pages/StepByStepPage/StepByStepPage.tsx";
import {CutJobPage} from "../../pages/CutJobPage/CutJobPage.tsx";

function Router(){

    return(
            <Routes>
                <Route path={"/job/:jobId/stepByStep"} element={<StepByStepPage />}/>
                <Route path={"/job/:jobId"} element={<CutJobPage/>}/>
                <Route path={"/"} element={<OverviewPage/>}/>
            </Routes>
    )
}
export default Router;