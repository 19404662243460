import {CssBaseline} from "@mui/material";
import Navigation from "./components/Navigation/Navigation.tsx";
import {KyuiWrapper} from "@laser-project/kyui";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import Router from "./components/Router/Router.tsx";
import {BrowserRouter} from "react-router-dom";
import {AuthWrapper} from "./components/AuthWrapper/AuthWrapper.tsx";
import {SignInCheck} from "./components/SignInCheck/SignInCheck.tsx";

const queryClient = new QueryClient()

function App() {

    return (
        <KyuiWrapper enableAuto={false}>
            <AuthWrapper>
                <SignInCheck>
                    <BrowserRouter>
                        <QueryClientProvider client={queryClient}>
                            <CssBaseline/>
                            <Navigation>
                                <Router/>
                            </Navigation>
                        </QueryClientProvider>
                    </BrowserRouter>
                </SignInCheck>
            </AuthWrapper>
        </KyuiWrapper>
    )
}

export default App
