import AbstractRest from "./AbstractRest.ts";
import axios, {AxiosResponse} from "axios";
import {SuccessResponseData} from "../types/ReturnHelpers.ts";
import {IAction} from "../types/models/Action.ts";
import {IStep} from "../types/models/Step.ts";
import {IJobSpec} from "../types/models/Job.ts";
import {IPopulatedJob} from "../types/FrontendTypes.ts";
import {IFeature} from "../types/models/Feature.ts";

class JobRest extends AbstractRest {

    constructor() {
        super("jobs");
    }

    getById(id: string): Promise<AxiosResponse<SuccessResponseData<IPopulatedJob>>> {
        return axios.get(this.baseUrl + "/" + id);
    }

    getByStatus(statuses: string[]): Promise<AxiosResponse<SuccessResponseData<IPopulatedJob[]>>> {
        const statusQuery = statuses.map(status => `status=${status}`).join('&');
        return axios.get(`${this.baseUrl}?${statusQuery}`);
    }

    getSteps(id: string): Promise<AxiosResponse<SuccessResponseData<IStep[]>>> {
        return axios.get(this.baseUrl + "/" + id + "/steps")
    }

    getFeaturesByJobId(id: string): Promise<AxiosResponse<SuccessResponseData<IFeature[]>>> {
        return axios.get(this.baseUrl + "/" + id + "/features")
    }

    createJobByFile(data: FormData): Promise<AxiosResponse<SuccessResponseData<IFeature[]>>> {
        return axios.post(this.baseUrl + "/file", data)
    }

    getActions(jobId: string, stepId: string): Promise<AxiosResponse<SuccessResponseData<IAction[]>>> {
        return axios.get(this.baseUrl + "/" + jobId + "/steps/" + stepId + "/actions")
    }
    
    createJob(job: IJobSpec): Promise<AxiosResponse<SuccessResponseData<IPopulatedJob>>> {
        return axios.post(this.baseUrl, job)
    }

    duplicate(jobId: string): Promise<AxiosResponse<SuccessResponseData<IPopulatedJob>>> {
        return axios.post(this.baseUrl + "/" + jobId + "/duplicate")
    }

    reset(jobId: string): Promise<AxiosResponse<SuccessResponseData<IPopulatedJob>>> {
        return axios.post(this.baseUrl + "/" + jobId + "/reset")
    }

    delete(jobId: string){
        return axios.delete(this.baseUrl+"/"+jobId)
    }

}

export default JobRest;