import React, {useMemo} from "react";
import ImageRest from "../../../../rest/ImageRest.ts";
import {animated, useSpring} from "@react-spring/web";
import {DedupeAnimationProps} from "./HideAnimation.types.ts";
import {StandardCard} from "@laser-project/kyui";

export function HideAnimation (props: DedupeAnimationProps)  {
    const imageRest = useMemo(() => new ImageRest(), []);

    const {opacity} = useSpring({
        from: {opacity: props.children.keep ? 1 : 0.1},
        to: {
            opacity: props.children.keep ? 1 : 0.0, // TODO use blur instead
        },
        delay: 500,
        onRest: (props.onComplete ? () => props.onComplete() : undefined)
    })

    return (
        <animated.div style={{
            opacity: opacity,
        }}>
            <StandardCard
                image={imageRest.getUrlById(props.children.imageId)}
                imageAlt={""}
                title={props.children.description}
                chips={props.children.chips}
                type={props.children.entityType}
            />
        </animated.div>
    )
}