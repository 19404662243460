import {animated, easings, useSpring} from "@react-spring/web";
import React from "react";
import {SideExpanderProps} from "./SideExpander.types.ts";
import {useTheme} from "@mui/material";

export function SideExpander(props: SideExpanderProps) {

    const theme = useTheme()
    const spacing = Number(theme.spacing(3).slice(0, -2))


    const {opacity, x} = useSpring({
        from: {opacity: 1, x: (-(props.width + spacing) * props.order)},
        to: {
            opacity: 1,
            x: 0
        },
        config: {
            duration: 1000,
            easing: easings.easeInOutCubic
        },
        onRest: (props.onComplete ? () => props.onComplete() : undefined)
    })

    return (
        <animated.div style={{
            opacity: opacity,
            width: "100%",
            x
        }}>
            {props.children}
        </animated.div>
    )
}
