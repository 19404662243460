import React, {useEffect, useMemo, useState} from "react";
import JobRest from "../../rest/JobRest.ts";
import ImageRest from "../../rest/ImageRest.ts";
import {useNavigate} from "react-router-dom";
import {IFeature} from "../../types/models/Feature.ts";
import {useCutJobApi} from "./useCutJobApi";
import {uniqBy} from "lodash";
import {IGauge, IGaugeSpec} from "../../types/models/Gauge.ts";
import {enqueueSnackbar} from "notistack";
import {GaugesSlider} from "./GaugesSlider";
import {FeatureSlider} from "./FeatureSlider";
import {JOB_STATUS} from "../../types/models/Job.ts";
import {Box, Container, Skeleton, Stack, Typography} from "@mui/material";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner.tsx";
import {CardSlider} from "./CardSlider";
import {Expandable, ImageHeader} from "@laser-project/kyui";
import Statement from "../../components/Statements/Statement.tsx";
import {Clear, DoneOutlined} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

export function CutJob({jobId}: Readonly<{ jobId?: string }>) {

    const jobRest = useMemo(() => new JobRest(), []);
    const imageRest = useMemo(() => new ImageRest(), []);
    const [filteredFeatures, setFilteredFeatures] = React.useState<IFeature[] | null>(null);
    const [images, setImages] = useState([])
    const navigate = useNavigate();
    const {
        handleUserInputSubmit,
        handleStart,
        isSubmitting,
        activeActionId,
        job,
        lastErrorMessage,
        isStartingJob
    } = useCutJobApi({jobId})

    useEffect(() => {
        if (!jobId) {
            console.warn("Did not have jobId. Aborting")
            return;
        }
        jobRest
            .getFeaturesByJobId(jobId)
            .then(response => {
                const allFeatures = response.data.data;
                let features: IFeature[] = uniqBy(allFeatures, (feature: IFeature) => feature.groupingKey);
                const blacklistedDependencyKeys: string[] = []
                features = features.map(feature => {
                    const newFeature = {...feature};
                    const cleanedDeps: (IGauge | IGaugeSpec)[] = [];
                    newFeature.dependencies?.forEach(dep => {
                        if (!blacklistedDependencyKeys.includes(dep.groupingKey)) {
                            blacklistedDependencyKeys.push(dep.groupingKey);
                            cleanedDeps.push(dep);
                        }
                    })
                    newFeature.dependencies = cleanedDeps;
                    return newFeature;
                })

                features.forEach(
                    feature => feature.entityType = "feature"
                )

                setFilteredFeatures(features)
            })
    }, [jobId, jobRest]);

    useEffect(() => {
        setImages([imageRest.getUrlById(job?.imageId ?? "")])
    }, [imageRest, job, job?.imageId]);

    useEffect(() => {
        if (!lastErrorMessage || lastErrorMessage === "") {
            return;
        }
        enqueueSnackbar(lastErrorMessage, {
            variant: 'error',
        })
    }, [lastErrorMessage]);


    function renderGauges() {
        return <GaugesSlider filteredFeatures={filteredFeatures}/>
    }

    function renderFeatures() {
        return <FeatureSlider filteredFeatures={filteredFeatures}/>
    }

    function renderJobPerStage() {

        switch (job?.status) {
            case JOB_STATUS.CREATED: {
                return (
                    <Stack spacing={3}>
                        <LoadingSpinner message={"Model is being analyzed"}/>
                        {renderFeatures()}
                    </Stack>
                )
            }
            case JOB_STATUS.DEPENDENCIES_ANALYZED:
                return (
                    <Stack spacing={3}>
                        <Expandable title={"Gauges"}>
                            {renderGauges()}
                        </Expandable>
                        <Expandable title={"Features"}>
                            {renderFeatures()}
                        </Expandable>
                    </Stack>
                )
            case JOB_STATUS.WAITING_FOR_INPUT:
            case JOB_STATUS.RUNNING:
                return (
                    <Stack spacing={3}>
                        <CardSlider
                            handleUserInputSubmit={handleUserInputSubmit}
                            elements={job.actionOrder ?? []}
                            activeElementId={activeActionId}
                            currentSubmitting={isSubmitting}
                        />
                        <Expandable title={"Gauges"}>
                            {renderGauges()}
                        </Expandable>
                        <Expandable title={"Features"}>
                            {renderFeatures()}
                        </Expandable>
                    </Stack>
                )
            case JOB_STATUS.SUCCESS:
                return (
                    <Box pb={10}>
                        <Statement icon={<DoneOutlined/>} message={"Your Model is done!"}/>
                    </Box>
                )
            case JOB_STATUS.FAILED:
                return (
                    <Box pb={10}>
                        <Statement icon={<Clear/>} message={"Your Model could not be produced!"}/>
                    </Box>
                )
            default:
                return "Unknown Status"
        }
    }

    function renderImageHeaderActions() {

        return [
            <LoadingButton
                key={"makeButton"}
                color={"secondary"}
                loading={isStartingJob}
                disabled={job?.status !== JOB_STATUS.DEPENDENCIES_ANALYZED}
                onClick={handleStart}
                variant={"contained"}
            >
                Make
            </LoadingButton>
        ]

    }

    if (!jobId) {
        return <Statement icon={<Clear/>} message={"No Job Specified"}/>
    }

    return (
        <Box sx={{width: '100%', height: "100%", overFlowY: ""}}>
            <Container sx={{p: 4}}>
                <Stack spacing={3}>
                    <ImageHeader
                        images={images}
                        isLoading={!job}
                        menuActions={[{
                            title: "Step by Step",
                            onClick: () => navigate("/job/" + jobId + "/stepByStep")
                        }]}
                        actions={renderImageHeaderActions()}
                    />
                    <Typography variant={"h1"} gutterBottom>{job?.title ?? <Skeleton/>}</Typography>
                    {renderJobPerStage()}
                </Stack>
            </Container>
        </Box>
    )

}